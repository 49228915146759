import React, { PureComponent } from 'react'
import Header from '../../molecules/Header'
import Menu from '../../organisms/Menu'
import Page from '../../templates/Page'
import { fetchCategories } from '../../../lib/api'

export default class Categories extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { categories: null }
  }

  async loadCategories () {
    this.props.startLoading()
    const categories = await fetchCategories()
    this.props.stopLoading()
    this.setState({ categories })
  }

  componentWillMount () {
    this.loadCategories()
  }

  render () {
    const { children, uri } = this.props
    const { categories } = this.state

    const current = uri.split('/').pop()

    return <Page>
      <Header />
      <Menu current={current} categories={categories} />
      { children }
    </Page>
  }
}
