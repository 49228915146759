import React, { Component } from 'react'
import { compose, withProps } from 'recompose'
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  OverlayView
} from 'react-google-maps'
import styled from 'styled-components'

const {
  REACT_APP_API_KEY: API_KEY = 'AIzaSyD80ZifqSr-H_yXWISv36z8P3tKaW1QFD0'
} = process.env

const withValidCoords = locations =>
  locations.filter(
    location =>
      typeof location.lat === 'number' && typeof location.lng === 'number'
  )

const MarkerLabel = styled.div`
  background-color: #000;
  color: #fff;
  padding: 8px;
  text-transform: uppercase;
  transform: translate(-50%, -75%);
`

class LocationMap extends Component {
  constructor (props) {
    super(props)
    this.googleMap = null
    this.state = { visibleOverlay: null }
    this.onMapMounted = this.onMapMounted.bind(this)
    this.fitMapBounds = this.fitMapBounds.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.locations.length !== this.props.locations.length) {
      this.fitMapBounds()
    }
  }

  onMapMounted (ref) {
    // Store the raw Google map instance

    if (ref) {
      this.googleMap = ref
      this.fitMapBounds()
    }
  }

  fitMapBounds () {
    const { locations } = this.props

    if (locations) {
      const bounds = new google.maps.LatLngBounds() // eslint-disable-line
      withValidCoords(locations).forEach(location => {
        bounds.extend(
          new google.maps.LatLng(location.lat, location.lng) // eslint-disable-line
        )
      })
      this.googleMap.fitBounds(bounds)
    }
  }

  render () {
    const { locations } = this.props
    const { visibleOverlay } = this.state

    if (!locations) {
      return null
    }

    const withCoords = withValidCoords(locations)

    if (!withCoords) {
      return null
    }

    return (
      <GoogleMap
        ref={this.onMapMounted}
        options={{ mapTypeControl: false }}
        defaultZoom={8}
        defaultCenter={{ lat: 45.5231, lng: -122.6765 }}
      >
        {withCoords.map((location, index) => {
          const position = {
            lat: location.lat,
            lng: location.lng
          }

          const overlayIsVisible = visibleOverlay === location.name

          return (
            <Marker
              key={index}
              position={position}
              title={location.name}
              zIndex={index}
              onClick={this.onMarkerClick.bind(this, location)}
            >
              {overlayIsVisible ? (
                // @todo Click and scroll to detail..?
                <OverlayView
                  position={position}
                  zIndex={index + 10}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <MarkerLabel>{location.name}</MarkerLabel>
                </OverlayView>
              ) : null}
            </Marker>
          )
        })}
      </GoogleMap>
    )
  }

  onMarkerClick ({ name }, e) {
    const { visibleOverlay } = this.state
    this.setState({
      visibleOverlay: visibleOverlay === name ? null : name
    })
  }
}

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${API_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(LocationMap)
