import React, { PureComponent } from 'react'
import Container from '../../atoms/Container'

class Page extends PureComponent {
  render () {
    const { children } = this.props
    return <Container>{children}</Container>
  }
}

export default Page
