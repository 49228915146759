import React, { Fragment, PureComponent } from 'react'
import { Link } from '@reach/router'
import styled, { keyframes } from 'styled-components'
import avatar from './avatar.jpg'
import x from './x.svg'

const AVATAR_SIZE = 50

const fadeIn = keyframes`
 from {
  opacity: 0;
 }

 to {
  opacity: 1;
 }
`

const zoomIn = keyframes`
 from {
  transform: scale(0.6);
 }

 to {
  transform: scale(1);
 }
`

const HeaderElement = styled.header`
  border-bottom: 8px solid #000;
  display: flex;
  padding: 2em 0 0.25em;

  .text {
    flex: 1;
  }

  h1 {
    font-size: 3rem;
    ${props => props.theme.font.heading};
    line-height: 1em;

    em {
      color: #fff;
    }
  }

  small {
    ${props => props.theme.font.heading};
    font-size: 1.5rem;
  }

  a {
    display: block;
    padding-left: 10px;
    position: relative;
  }

  .avatar,
  .close {
    border-radius: 50%;
    height: ${AVATAR_SIZE}px;
    width: ${AVATAR_SIZE}px;
  }

  .close {
    animation-name: ${fadeIn};
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;

    img {
      animation-name: ${zoomIn};
      display: block;
      height: 22px;
      transform-origin: center;
      width: 22px;
    }

    &,
    img {
      animation-duration: 500ms;
      animation-timing-function: ${({ theme }) => theme.easing.easeOutCubic};
    }
  }

  &.about-page {
    border-bottom: none;
  }
`

class Header extends PureComponent {
  render () {
    const { onAboutPage } = this.props
    const showTitle = !onAboutPage

    return <HeaderElement className={onAboutPage ? 'about-page' : ''}>
      <div className='text'>
        { showTitle
          ? <Fragment><h1>Catalog of <em>Portland</em> Food</h1><small>(and drinks)</small></Fragment>
          : <h1>&nbsp;</h1>
        }
      </div>
      <Link to={onAboutPage ? '/' : '/about'}>
        { onAboutPage ? <div className='close'><img src={x} alt='Close' /></div> : null }
        <img src={avatar} alt='Josh Kenyon' className='avatar' />
      </Link>
    </HeaderElement>
  }
}

Header.defaultProps = {
  onAboutPage: false,
  showTitle: true
}

export default Header
