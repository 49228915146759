import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Loading from '../Loading'

const LoadingScreen = styled.div`
  background-color: rgba(244, 90, 69, 0.75);
  height: 100vh;
  left: 0;
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  pointer-events: ${({ loading }) => (loading ? 'auto' : 'none')};
  position: fixed;
  top: 0;
  transition: opacity 250ms ease;
  width: 100vw;
  z-index: 100;
`

const StyledLoading = styled(Loading)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`

export default class LoadingOverlay extends PureComponent {
  render () {
    const { loading } = this.props
    return <LoadingScreen loading={loading}>
      <StyledLoading loading={loading} size={32} color='#333' />
    </LoadingScreen>
  }
}
