import styled from 'styled-components'

const Container = styled.div`
  box-sizing: border-box;
  color: ${props => props.theme.color.text};
  margin: 0 auto;
  max-width: 960px;
  padding: 0 ${props => props.theme.dimension.spacing}px;
  position: relative;
`

export default Container
