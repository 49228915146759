// Breakpoints

const breakpoint = {
  medium: '768px',
  large: '960px'
}

// Colors

const lightGrey = '#d3d3d3'
const orange = '#f45a45'

const color = {
  lightGrey,
  orange,

  // Contextualized

  background: orange,
  text: '#000'
}

// Dimensions

const dimension = {
  spacing: 20
}

// Fonts

const aaux = '"aaux-next", Helvetica, sans-serif'

const aauxRegular = {
  fontFamily: aaux,
  fontWeight: 400
}

const aauxBold = {
  fontFamily: aaux,
  fontWeight: 700
}

const paralucent = '"paralucent", Helvetica, sans-serif'

const paralucentBold = {
  fontFamily: paralucent,
  fontWeight: 600
}

const font = {
  aaux,
  aauxRegular,
  aauxBold,
  paralucent,
  paralucentBold,

  // Contextualized

  heading: paralucentBold,
  body: aauxRegular
}

// Easing

const easing = {
  easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}

// Transitions

const transition = {
  duration: '250ms',
  easing: 'ease'
}

export default {
  breakpoint,
  color,
  dimension,
  easing,
  font,
  transition
}
