import React from 'react'
import styled from 'styled-components'
import { enter } from '../../../styles/animations'
import Header from '../../molecules/Header'
import Page from '../../templates/Page'

const RESTAURANT_COUNT = 656

const VISIT_COUNT = 2317

const ARROW_SIZE = 12

const Text = styled.section`
  animation-duration: 500ms;
  animation-timing-function: ${({ theme }) => theme.easing.easeOutCubic};
  animation-name: ${enter};
  background-color: #fff;
  font-size: 1.2rem;
  margin-top: ${ARROW_SIZE / 2}px;
  padding: 10px 40px;
  position: relative;

  p {
    margin: 1em 0;
  }

  a {
    color: ${({ theme }) => theme.color.orange};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: bold;
  }

  &:after {
    bottom: 100%;
    left: calc(100% - ${ARROW_SIZE * 2}px);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: ${ARROW_SIZE}px;
    margin-left: -${ARROW_SIZE}px;
  }

  @media (min-width: ${props => props.theme.breakpoint.medium}) {
    padding-left: 60px;
    padding-right: 60px;
  }
`

export default () => {
  return <Page>
    <Header onAboutPage />
    <Text>
      <p>
        Hi, I&rsquo;m Josh! I gotta admit, i have a thing for food. In fact, I&rsquo;ve track every restaurant, food cart, bar, coffee shop and dessert
        spot since moving to Portland in 2009. (i know crazy, right?) I&rsquo;ve put together FoodCatalog, a list of the Portland food scene
        to help you answer that age-old question &ldquo;where should we eat in Portland?&rdquo;
      </p>
      <p><strong>Current count: {RESTAURANT_COUNT}</strong></p>
      <p><strong>Current visit count: {VISIT_COUNT}</strong></p>
      <p>What&rsquo;s your perfect food day? Every one of your favorite meals one after the other, morning to night. No? Just me? Ok, here goes:</p>
      <ol>
        <li>
          7am Breakfast: Ha Vl&rsquo;s B&uacute;n B&ograve; Huế- a savory sour vietnamese soup that makes you say, &ldquo;what is this ring of oil
          around my lips and how do I get more?&rdquo;
        </li>
        <li>10:20am Mid morning snack: Uni nigiri- yes, raw sea urchin for second breakfast</li>
        <li>12pm Lunch: Yuzu Ratan ramen at Afuri</li>
        <li>2pm Pick me up: Prosciutto and bread sando with an Americano at Sterling Coffee</li>
        <li>5:30pm Dinner: Fried chicken at The Waiting Room</li>
        <li>7pm Second dinner: Omakase at Nimblefish</li>
        <li>9pm Dessert: Oysters at Jacqueline</li>
        <li>10:30pm Second Dessert: cheese&rsquo;s and wine at St Jack</li>
      </ol>
      <p>Want me to help you create a custom food day experience? <a href='mailto:pdxfoodcatalog@gmail.com?Custom+Food+Day+Me'>email me</a></p>
    </Text>
  </Page>
}
