import React from 'react'
import styled from 'styled-components'
import LocationListItem from '../LocationListItem'
import arrowIcon from './arrow.svg'

const LocationList = styled.ul`
  padding: 0 10px 10px;

  li + li {
    border-top: 1px solid ${props => props.theme.color.lightGrey};
  }
`

const ArrowButton = styled.button`
  background-color: ${props => props.theme.color.orange};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 60px;
  margin: 0 auto;
  outline: none;
  transform: translateY(50%);
  width: 60px;
`

const ArrowIcon = styled.img`
  margin: 0 auto;
  transform: translateY(-50%);
  width: 30px;
`

const onArrowClick = () => {
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth'
  })
}

export default ({ locations }) => {
  if (!locations) {
    return null
  }

  return (
    <div>
      <LocationList>
        {locations.map((location, index) => {
          return (
            <li key={index}>
              <LocationListItem location={location} />
            </li>
          )
        })}
      </LocationList>
      <ArrowButton onClick={onArrowClick}>
        <ArrowIcon src={arrowIcon} alt='Scroll to the top' />
      </ArrowButton>
    </div>
  )
}
