import React from 'react'
import {
  createHistory,
  LocationProvider
} from '@reach/router'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import theme from './styles/theme'

import './styles/reset.css'
import './styles/global.css'

const history = createHistory(window)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <LocationProvider history={history}>
      <App />
    </LocationProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
registerServiceWorker()
