import React, { Fragment, PureComponent } from 'react'
import { Router } from '@reach/router'
import LoadingOverlay from '../atoms/LoadingOverlay'
import AboutPage from '../pages/About'
import CategoriesPage from '../pages/Categories'
import CategoryPage from '../pages/Category'

export default class App extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { loading: false }
    this.startLoading = this.startLoading.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
  }

  startLoading () {
    this.setState({ loading: true })
  }

  stopLoading () {
    this.setState({ loading: false })
  }

  render () {
    const { loading } = this.state

    return <Fragment>
      <LoadingOverlay loading={loading} />
      <Router>
        <AboutPage path='/about' />
        <CategoriesPage
          path='/categories'
          default
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}>
          <CategoryPage
            path='/categories/:slug'
            startLoading={this.startLoading}
            stopLoading={this.stopLoading} />
        </CategoriesPage>
      </Router>
    </Fragment>
  }
}
