import React from 'react'
import styled from 'styled-components'
import heartIcon from './heart.svg'

const ListItem = styled.div`
  padding: 20px 10px;
`

const Header = styled.header`
  align-items: flex-start;
  display: flex;
`

const HeaderText = styled.div`
  flex: 1;
  line-height: 1.5em;
`

const LocationName = styled.h3`
  ${props => props.theme.font.heading};
  font-size: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoint.medium}) {
    font-size: 1.75rem;
  }
`

const LocationType = styled.h4`
  ${props => props.theme.font.body};
  display: block;
  font-size: 1.15rem;
  margin-top: 0.5em;

  @media (min-width: ${props => props.theme.breakpoint.medium}) {
    font-size: 1.25rem;
  }
`

const HeaderStats = styled.div`
  align-items: center;
  display: flex;
  margin-top: -6px;
  padding-left: 10px;
`

const Rating = styled.span`
  display: flex;
  white-space: nowrap;
`

const Heart = styled.img`
  display: inline-block;
  height: 12px;
  margin: 0 2px;
  white-space: nowrap;

  @media (min-width: ${props => props.theme.breakpoint.medium}) {
    height: 20px;
  }
`

const Visits = styled.span`
  display: inline-block;
  font-size: 1rem;
  padding-left: 10px;
  ${props => props.theme.font.aauxBold};

  @media (min-width: ${props => props.theme.breakpoint.medium}) {
    font-size: 1.25rem;
  }
`

const Description = styled.div`
  font-size: 1.25rem;
  p {
    margin: 1em 0;
  }

  a {
    color: #999;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default ({ location }) => {
  const { name, type, address, notes, rating, visits } = location

  const hearts = []

  if (rating) {
    for (let i = 0; i < rating; i++) {
      hearts.push(<Heart src={heartIcon} key={i} />)
    }
  }

  let visitsDisplay

  if (visits) {
    try {
      visitsDisplay = parseInt(visits, 10)
    } catch (err) {
      visitsDisplay = visits
    }
  }

  let addressURL

  if (address) {
    addressURL = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`
  }

  return (
    <ListItem>
      <Header>
        <HeaderText>
          <LocationName>{name}</LocationName>
          {type ? <LocationType>{type}</LocationType> : null}
        </HeaderText>
        <HeaderStats>
          {hearts ? <Rating>{hearts}</Rating> : null}
          {visitsDisplay ? (
            <Visits>
              {visitsDisplay} Visit{visitsDisplay === 1 ? '' : 's'}
            </Visits>
          ) : null}
        </HeaderStats>
      </Header>
      <Description>
        {notes ? <p>{notes}</p> : null}
        {address ? (
          <p>
            <a href={addressURL} target='_blank' rel='noopener noreferrer'>
              {address}
            </a>
          </p>
        ) : null}
      </Description>
    </ListItem>
  )
}
