import React, { PureComponent } from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { enter } from '../../../styles/animations'

const Wrapper = styled.div`
  animation-duration: 500ms;
  animation-timing-function: ${({ theme }) => theme.easing.easeOutCubic};
  animation-name: ${enter};
  background-color: #fff;
  margin: ${props => props.theme.dimension.spacing}px 0;
  min-height: 46px;
  position: relative;
`

const Nav = styled.nav`
  font-size: 1rem;
  ${props => props.theme.font.heading};

  @media (min-width: ${props => props.theme.breakpoint.medium}) {
    font-size: 1.25rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    opacity: ${({ loading }) => (loading ? 0 : 1)};
    padding: 10px;
    transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easing};
  }

  li {
    box-sizing: border-box;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px;

    @media (min-width: ${props => props.theme.breakpoint.medium}) {
      flex-basis: 33.33%;
    }

    @media (min-width: ${props => props.theme.breakpoint.large}) {
      flex-basis: 25%;
    }
  }

  a {
    background-color: #fff;
    border: 2px solid #000;
    display: block;
    padding: 0.75em 0;
    text-align: center;
    text-decoration: none;
    transition-duration: ${props => props.theme.transition.duration};
    transition-property: background-color, border-color, color;
    transition-timing-function: ${props => props.theme.transition.easing};
    white-space: nowrap;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  li.-selected a {
    background-color: ${props => props.theme.color.orange};
    border-color: ${props => props.theme.color.orange};
    color: #fff;
  }
`

class Menu extends PureComponent {
  render () {
    const {
      categories,
      current
    } = this.props

    if (!categories || !categories.length) return null

    return <Wrapper>
      <Nav>
        <ul>
          {categories
            ? categories.map((category, index) => {
              return (
                <li
                  key={index}
                  className={
                    current && current === category.slug
                      ? '-selected'
                      : null
                  }
                >
                  <Link to={`/categories/${category.slug}`}>{category.title}</Link>
                </li>
              )
            })
            : null}
        </ul>
      </Nav>
    </Wrapper>
  }
}

export default Menu
