const { REACT_APP_API_HOST: API_HOST = 'http://localhost:3001' } = process.env

export const fetchCategories = async () => {
  const response = await fetch(`${API_HOST}/category`)
  return response.json()
}

export const fetchCategory = async slug => {
  const response = await fetch(`${API_HOST}/category/${slug}`)
  return response.json()
}
