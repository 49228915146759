import React, { Component } from 'react'
import styled from 'styled-components'
import { fetchCategory } from '../../../lib/api'
import LocationList from '../../organisms/LocationList'
import LocationMap from '../../organisms/LocationMap'

const Container = styled.div`
  background-color: #fff;
  margin-bottom: ${props => props.theme.dimension.spacing}px;
`

const ListHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.orange};
  color: ${props => props.theme.color.orange};
  display: flex;
  padding: 0.75em ${props => props.theme.dimension.spacing}px;

  h2 {
    flex: 1;
    font-size: 1.25rem;
    ${props => props.theme.font.heading};
  }
`

export default class Category extends Component {
  constructor (props) {
    super(props)
    this.state = { category: null }
  }

  async getCategory (slug) {
    if (slug) {
      this.props.startLoading()
      const category = await fetchCategory(slug)
      this.props.stopLoading()
      this.setState({ category })
    } else {
      this.setState({ category: null })
    }
  }

  async componentWillMount () {
    const { slug } = this.props
    this.getCategory(slug)
  }

  componentDidUpdate (prevProps) {
    const { slug: prevSlug } = prevProps
    const { slug } = this.props
    const { category } = this.state

    if (prevSlug !== slug) {
      this.getCategory(slug)
    }

    // Scroll to map

    if (category && category.locations && category.locations.length) {
      const node = document.querySelector('#map')

      if (node) {
        window.scrollTo({
          left: 0,
          top: node.offsetTop - 20,
          behavior: 'smooth'
        })
      }
    }
  }

  render () {
    const { slug } = this.props
    const { category } = this.state

    if (!slug || !category) return null

    const { locations } = category

    return <Container id='map'>
      <LocationMap locations={locations} />
      <ListHeader>
        <h2>{category.title}</h2>
        {locations ? (
          <h3>
            {locations.length} Restaurant{locations.length !== 1
              ? 's'
              : ''}
          </h3>
        ) : null}
      </ListHeader>
      <LocationList locations={locations} />
    </Container>
  }
}
